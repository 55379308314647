.bookmaker {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 16px;
  border-bottom: var(--betslip-betStakeBorder);
}

.bookmakerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  position: relative;

  & > div:first-child {
    display: flex;
    align-items: center;

    & span {
      padding-left: 16px;
    }
  }

  > button {
    margin-left: auto;
  }
}

.circle {
  margin-left: 16px;
  padding: 0px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  border: none;
  background: var(--betslip-highlight);
}

.arrowRight {
  fill: var(--betslip-background);
}

.returnsAndSelectionToggle {
  display: flex;
  padding-top: 16px;
  justify-content: space-between;

  &.expanded {
    padding-bottom: 16px;
    border-bottom: 1px dashed var(--betslip-borderBottomColor);
    margin-bottom: 16px;
  }
}

.selectionsToggle {
  display: flex;
  flex-grow: 2;
  justify-content: flex-end;
  height: 24px;
  background-color: transparent;
  border: none;
  color: var(--betslip-defaultText);

  & span {
    font-size: 0;
  }
}

.selection {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed var(--betslip-borderBottomColor);
  padding-bottom: 16px;
  margin-bottom: 16px;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.selectionDescription {
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0 0 8px 0;
}

.selectionMeta {
  font-size: 0.75rem;
  margin: 0;
}

.selectionActionButton {
  display: flex;
  font-size: 0;
  color: var(--contentHeaderFontColor);
  background-color: var(--betslip-disabledButtonBackgroundColor);
  height: 26px;
  width: 26px;
  border-radius: 50%;
  border: none;
  align-items: center;
  justify-content: center;
  align-self: center;
  transform: rotate(0);
  transition: all 0.3s ease-in-out;

  svg {
    font-size: 1rem;
  }
}

.selectionActionButton-isDisabled {
  @extend .selectionActionButton;
  background-color: var(--betslip-enabledButtonBackgroundColor);
  transform: rotate(-45deg);
}

.strikethrough {
  color: var(--betslip-borderBottomColor);
}
